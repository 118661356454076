<template>
    <the-header></the-header>
    <div class="container">
    <div class="row pt-5" v-show="getDetail==2 || getDetail==1">
        <div class=" col-lg-6 col-md-6 col-sm-12">
            <base-card>
                <router-link to="/Client">
                    <h2>
                        Client
                    </h2>
                </router-link>
            </base-card>
        </div>
        <div class=" col-lg-6 col-md-6 col-sm-12">
            <base-card>
                <router-link to="/Project">
                    <h2>
                        Projects
                    </h2>
                </router-link>
            </base-card>
        </div>
        <div class=" col-lg-6 col-md-6 col-sm-12">
            <base-card>
                <router-link to="/payment">
                    <h2>
                        Payments
                    </h2>
                </router-link>
            </base-card>
        </div>
        <div class=" col-lg-6 col-md-6 col-sm-12">
            <base-card>
                <router-link to="/Purchase">
                    <h2>
                        Purchase
                    </h2>
                </router-link>
            </base-card>
        </div>
        <div class=" col-lg-6 col-md-6 col-sm-12">
            <base-card>
                <router-link to="/worker">
                    <h2>
                        Worker Creation/Suspend 
                    </h2>
                </router-link>
            </base-card>
        </div>
        <div class=" col-lg-6 col-md-6 col-sm-12">
            <base-card>
                <router-link to="/report">
                    <h2>
                        Report
                    </h2>
                </router-link>
            </base-card>
        </div>
    </div>
    <div class="row pt-5" v-show="getDetail==3">
        <UserDashBorad></UserDashBorad>
    </div>
    </div>
    
</template>
<script>
import TheHeader from '../layout/TheHeader.vue'
import UserDashBorad from '../user/UserDashBoard.vue'
export default {
    components:{
        TheHeader,
        UserDashBorad
    },computed:{
        getDetail(){
        return this.$store.getters.getRole;
    },
    }

}
</script>
<style  scoped>
h2{
    text-align: center;
    text-decoration: none;
    color:#000;
    text-decoration-color: #fff;
}
.card{
    max-width: 100%;
    padding: 2rem 1rem;
}
</style>