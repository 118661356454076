<template>
  <base-dialog :show="!!error" title="An Error" @close="handleError"
    ><p>{{ error }}</p></base-dialog
  >
  <base-dialog fixed :show="isLoading" title="Processing...">
    <base-spinner></base-spinner>
  </base-dialog>
  <form class="p-5 border">
    <fieldset>
      <legend v-if="!update">Add Payment</legend>
      <legend v-else>Update Payment</legend>
      <div class="row">
        <input type="hidden" name="id" id="id" v-model.trim="id">
        <input type="hidden" name="userid" id="userid" v-model.trim="userid">
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="payment_date"> Date <span>*</span> </label>
          <input
            type="date"
            id="payment_date"
            ref="payment_date"
            name="payment_date"
            class="form-control form-control-sm"
            :class="{ invalid: invalidDate }"
            v-model="payment_date"
            @blur="validInput"
          />
          <span v-if="invalidDate" :class="{ invalid: invalidDate }"
            >Choose Any Date</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="client_name"> Client <span>*</span></label>
          <select
            type="text"
            id="client_name"
            ref="client_name"
            name="client_name"
            class="form-control form-control-sm"
            :class="{ invalid: invalidClientOption }"
            v-model.trim="client_name"
            @blur="validInput"
            @change='getProjectOptions'>
          >
          <option v-for="option in ClientOption"  :key="option.int_party_id" :value="option.int_party_id">{{option.txt_name}}</option>
          </select>
          <span
            v-if="invalidClientOption"
            :class="{ invalid: invalidClientOption }"
            >Choose Client</span
          >
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="project_id"> Project <span>*</span></label>
          <select
            type="text"
            id="project_id"
            ref="project_id"
            name="project_id"
            class="form-control form-control-sm"
            :class="{ invalid: invalidProjectOption }"
            v-model.trim="project_id"
            :selected="project_id"
            @blur="validInput"
          >
          <option v-for="option in ProjectOption"  :key="option.int_project_id" :value="option.int_project_id">{{option.txt_project_name}}</option>
          </select>
          <span
            v-if="invalidProjectOption"
            :class="{ invalid: invalidProjectOption }"
            >Choose Project</span
          >
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="bill_amount"> Amount</label>
          <input
            type="number"
            id="bill_amount"
            name="bill_amount"
            ref="bill_amount"
            class="form-control form-control-sm"
            :class="{ invalid: invalidAmount }"
            v-model.number="bill_amount"
            @blur="validInput"
          />
          <span
            v-if="invalidAmount"
            :class="{ invalid: invalidAmount }"
            >Inavlid Amount</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="payment_detail"> Payment  Details<span>*</span></label>
          <textarea
          id="payment_detail"
            name="payment_detail"
            ref="payment_detail"
            class="form-control form-control-sm"
            v-model.number="payment_detail"
            @blur="validInput"></textarea>
            
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="file" class="btn btn-sm fa fa-upload" > Add Image</label>
          <input type="file" ref="file" name="file" id="file" hidden  @change="showFileName($event)"/>
          <input type="hidden" v-model="file_name" />
          <span v-html="file"></span>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-right p-2" v-if="update">
          <base-button  @click.prevent="updateData" mode="btn"> Update </base-button >
          <base-button @click="close" mode="btn"> Close</base-button >
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-right p-2" v-else>
          <base-button @click="close" mode="btn" > Close</base-button >
          <base-button  @click.prevent="saveData(true)" mode="btn" > Save & Close </base-button >
          <base-button  @click.prevent="saveData" mode="btn"> Save & New </base-button >
        </div>
      </div>
    </fieldset>
  </form>
</template>
<script>
import BaseButton from '../UI/BaseButton.vue';
export default {
  components: { BaseButton },
  emits: ["close", "load","msg"],
  props: ["update"],
  data() {
    return {
      id:null,
      payment_date:null,
      client_name: null,
      project_id:null,
      userid:null,
      bill_amount:null,
      payment_detail:null,
      isLoading: false,
      error: null,
      invalidDate: false,
      invalidClientOption:false,
      invalidProjectOption:false,
      invalidAmount:false,
      file_name:null,
      file:null,
      valid: false,
      ProjectOption:null,
      ClientOption:null,
    };
  },
  methods: {
    async saveData(selfclose = false) {
      let valid = null;
      this.validInput();
      if (
        this.invalidDate ||
        this.invalidClientOption ||
        this.invalidProjectOption ||
        this.invalidAmount
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid) {
        this.isLoading = true;
        try {
          this.$store.dispatch("payment/addPayment", {
            date: this.payment_date,
            client:this.client_name,
            project:this.project_id,
            bill_amount:this.bill_amount,
            payment_detail:this.payment_detail,
            file : this.$refs.file.files[0],
          });
        } catch (error) {
          this.error = error;
        }
        setTimeout(() => {
          this.isLoading = false;
          if (selfclose == true) {
            this.$emit("close");
          }
          this.$emit("msg","Payment Added Successfully")
          this.$emit("load");
          this.payment_date = null;
          this.client_name = null;
          this.project_id = null;
          this.bill_amount=null;
          this.payment_detail=null;
          this.id=null;
        }, 2000);
      }
    },

    getdata() {
      if (this.update) {
        this.$store.dispatch("payment/singleGetPayemnt", { id: this.update });
        this.isLoading = true;
        setTimeout(() => {
          this.setData();
          this.isLoading=false;
        }, 2000);
      }
    },
    handleError() {
      this.error = null;
    },
    close() {
      this.$emit("close");
    },
    getOption(){
       var date='';
      var today=new Date();
      if(today.getDate()<10){
        date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+0+today.getDate();
      }else{
        date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      }
      if(today.getMonth()<10){
        date=today.getFullYear()+'-'+0+(today.getMonth()+1)+'-'+today.getDate();
      }else{
        date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      }
      this.payment_date=date;
      this.$store.dispatch("payment/getOption", {id:0}); 
      this.isLoading=true;
      setTimeout(() => {
          this.setOption();
          this.isLoading=false;
        }, 2000); 
    },
    getProjectOptions(){
      var today=new Date();
      var date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.purchase_date=date;
      this.$store.dispatch("payment/getProjectOption", {id:this.client_name}); 
      this.isLoading=true;
      setTimeout(() => {
          this.setProjectOption();
          this.isLoading=false;
        }, 500); 
    },
    ItemChange(event) {
      this.selected = event.target.value;
      var ItemText = event.target.options[event.target.options.selectedIndex].text;
      if(ItemText!='other'){
          this.item_name=ItemText;
      }else{
        this.item_name=null;
      }
      this.item_id=event.target.value;
      this.validInput();
      
    },
    validInput() {
      if (!this.payment_date) {
        this.invalidDate = true;
        this.$refs.payment_date.focus();
      }else if (!this.client_name) {
        this.invalidClientOption = true;
        this.$refs.client_name.focus();
      }else if (!this.project_id) {
        this.invalidProjectOption = true;
        this.$refs.project_id.focus();
      }else if (!this.bill_amount) {
        this.invalidAmount = true;
        //this.$refs.bill_amount.focus();
      }

      if (this.payment_date) {
        this.invalidDate = false;
      }
      if (this.client_name) {
        this.invalidClientOption = false;
      }
      if (this.project_id) {
        this.invalidProjectOption = false;
      }
     
      if (this.bill_amount) {
        this.invalidAmount = false;
      }

    },
    setOption(){
     // this.ProjectOption= this.$store.getters["payment/getProjectOption"];
      this.ClientOption= this.$store.getters["payment/getClientOption"];
    },
    setProjectOption(){
      this.ProjectOption= this.$store.getters["payment/getProjectOption"];
      //this.ClientOption= this.$store.getters["payment/getClientOption"];
    },
    setData() {
        
      const Data = this.$store.getters["payment/getSinglePayment"];
        console.log(Data);
      this.id=Data[0].int_payment_id;
      this.payment_date = Data[0].dat_payment;
      this.client_name = Data[0].int_party_id ;
      this.file_name = Data[0].txt_payment_image;
       this.file = Data[0].txt_payment_image;
       this.userid = Data[0].int_user_id;
      this.getProjectOptions();
      setTimeout(()=>{
        this.project_id = Data[0].int_project_id ;
      },200)

      this.bill_amount = Data[0].dbl_amount;
      this.payment_detail = Data[0].txt_payment_detail;
    },
    async updateData() {
      let valid = null;
      this.validInput();
      if (
        this.invalidDate ||
        this.invalidClientOption ||
        this.invalidProjectOption ||
        this.invalidAmount
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid) {
        this.isLoading = true;
        try {
          this.$store.dispatch("payment/updatePayment", {
              id:this.id,
            date: this.payment_date,
            client:this.client_name,
            project:this.project_id,
            bill_amount:this.bill_amount,
            payment_detail:this.payment_detail,
            file : this.$refs.file.files[0],
            file_name :this.file_name,
            userid:this.userid,
          });
        } catch (error) {
          this.error = error;
        }
        setTimeout(() => {
          this.isLoading = false;
          this.$emit("msg","Payment Updated Successfully")
          this.$emit("load");
          this.$emit("close");
          this.payment_date = null;
          this.client_name = null;
          this.project_id = null;
          this.bill_amount=null;
          this.payment_detail = null;
        }, 2000);
      }
    },showFileName(event){
      this.file=event.target.files[0].name;      
    },
  },
  computed: {},
  created() {
    
    this.getdata();
    this.getOption();
  },
};
</script>
<style scoped>
label span {
  color: red;
}
.invalid {
  color: red;
}
select.invalid:focus,
select.invalid:active
input.invalid:focus,
input.invalid:active,
textarea.invalid:focus,
textarea.invalid:active,
textarea.invalid {
  border: 2px solid rgb(150, 145, 145);
  outline: 2px solid red;
}
</style>
