<template>
<div class="container-fluid" >
    <b class="float" @click="formTogglePurchase" v-show="work_type=='purchase'">
        <router-link to="" >
            <h2 class="fa fa-plus my-float text-white"></h2>
        </router-link>
    </b>
    <b class="float" @click="formTogglePayment" v-show="work_type=='payment'">
        <router-link to="" >
            <h2 class="fa fa-plus my-float text-white"></h2>
        </router-link>
    </b>
    <div class="row">
        <div class="col-sm-3 col-md-5 col-lg-4">
            <input type="date" name="date" id="date" class="form-control form-control-sm" @change="changeDate($event)" v-model="date">
        </div>
            <div class="col-sm-4 col-md-2 col-lg-4"></div>
            <div class="col-sm-3 col-md-5 col-lg-4 text-right">
                <select name="" id="" class="form-control form-control-sm" v-model="work_type" @change="changeType">
                <option value="purchase">Purchase</option>
                <option value="payment">Payment</option>
                 </select>
            </div>
            
    </div>
    <div class="row pt-3" >
        <transition name="msg" mode="out-in">
    <strong class="text-success">{{msg}}</strong>
    </transition>

    <add-purchase v-if="formPurchaseVisible" @close="formTogglePurchase" @load="getUserPurchaselist" :update="UpdatePurchaseData" @msg="showMessgae"></add-purchase>
    <add-payment v-if="formPaymentVisible" @close="formTogglePayment" @load="getUserPaymentlist" :update="updatePaymentData" @msg="showMessgae"></add-payment>

        <div class="py-2 table_div">
            <!--  Purchase Table -->
        <table class="table table-striped border" v-if="purchase">
        <thead>
            <tr>
            <th scope="col"></th>
            <th scope="col">Date</th>
            <th scope="col">Item Name</th>
            <th scope="col">Project Name</th>
            <th scope="col">Suppiler Name</th>
            <th scope="col">Amount</th>
            <th scope="col">Image</th>
            <th scope="col"><base-button class="m-0 p-2 border-none" @click="getpdf()"><i class="fa fa-download"></i> Download List</base-button></th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="getpuchase.length==0 && purchase==true">
                <td  colspan="8" class='text-center'>No Record Found Yet !</td>
            </tr>
            <tr v-else-if="purchase==true" v-for="data in getpuchase " :key="data.int_purchase_id">
            <th scope="row">{{data.int_purchase_id}}</th>
            <td>{{data.dat_purchase}}</td>
            <td>{{data.txt_item_name}}</td>
            <td>{{data.txt_project_name}}</td>
            <td>{{data.txt_supplier}}</td>
            <td>{{data.dbl_amount}}</td>
            <td><a :href="data.txt_bill_image" target="_blank"><img :src="data.txt_bill_image"></a></td>
            <td><base-button mode="icon" @click="updatePurchase(data.int_purchase_id)"><strong class="fas fa-edit" ></strong></base-button>
            </td>
            </tr>
            <tr v-show="getpuchase.length!=0 && purchase==true" style="text-align:right" >
                <td colspan="8" >
                    <ul class='pagination' >
                       <li class='page-item' :class="{active : item==getActive}" v-for="(item, index)  in getPagination" v-bind:key="index"><a href="#" class='page-link btn btn-sm' @click="pageredirect(item)" >{{getPaginationText[index]}}</a></li>
                    </ul>
                </td>
            </tr>            
        </tbody>
        </table>
            <!-- Payment Table -->
        <table class="table table-striped border" v-if="payment">
        <thead>
            <tr>
            <th scope="col"></th>
            <th scope="col">Date</th>
            <th scope="col">Client Name</th>
            <th scope="col">Project Name</th>
            <th scope="col">Amount</th>
            <th scope="col">Image</th>
            <th scope="col"><base-button class="m-0 p-2 border-none" @click="getpdf()"><i class="fa fa-download"></i> Download List</base-button></th>
           </tr>
        </thead>
        <tbody>
            <tr v-if="getpayment.length==0 && payment==true">
                <td  colspan="8" class='text-center'>No Record Found Yet !</td>
            </tr>
            
            <tr v-else-if="payment==true" v-for="data in getpayment " :key="data.int_payment_id">
            <th scope="row">{{data.int_payment_id}}</th>
            <td>{{data.dat_payment}}</td>
            <td>{{data.party_name}}</td>
            <td>{{data.txt_project_name}}</td>
            <td>{{data.dbl_amount}}</td>
            <td><a :href="data.txt_payment_image" target="_blank"><img :src="data.txt_payment_image"/></a></td>
            <td><base-button mode="icon" @click="UpdatePayment(data.int_payment_id)"><strong class="fas fa-edit" ></strong></base-button>
            </td>
            </tr>
            <tr v-show="getpayment.length!=0 && payment==true" style="text-align:right" >
                <td colspan="8" >
                    <!--<ul class='pagination' >
                       <li class='page-item'  v-for="pno in getPagination1.length" v-bind:key="getPagination1[pno-1]"><a href="#" class='page-link btn btn-sm' @click="pageredirectPayment(getPagination1[pno-1])" >{{getPaginationText1[pno-1]}}</a></li>
                    </ul>-->
                    <ul class='pagination' >
                       <li class='page-item' :class="{active : item==getActive1}" v-for="(item, index)  in getPagination1" v-bind:key="index"><a href="#" class='page-link btn btn-sm' @click="pageredirect(item)" >{{getPaginationText1[index]}}</a></li>
                    </ul>
                </td>
            </tr>   
        </tbody>
        </table>
    </div>
    </div>
</div>
    
</template>
<script>
import AddPurchase from './AddPurchase.vue'
import AddPayment from './AddPayment.vue'
export default {
    components:{
        AddPurchase,
        AddPayment
    },data(){
        return{
            formPurchaseVisible:false,
            formPaymentVisible:false,
            UpdatePurchaseData:false,
            updatePaymentData:false,
            msg:null,
            search:null,
            date:'4-11-2021',
            work_type:'purchase',
            purchase:true,
            payment:false,
        }
    },methods:{
        pageredirectPurchase(id){
            this.getUserPurchaselist(id,null,this.date);
        },
        pageredirectPayment(id){
            this.getUserPaymentlist(id,null,this.date);
        },
        getUserPurchaselist(pn=1,option=null,date1=null){
            this.purchase=true;
            this.payment=false;
            var date='';
            if(date1==null){
                var today=new Date();
                if(today.getDate()<10){
                     date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+0+today.getDate();
                }else{
                     date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                }
                if(today.getMonth()<10){
                    date=today.getFullYear()+'-'+0+(today.getMonth()+1)+'-'+today.getDate();
                }else{
                     date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                 }
                this.date=date;
                date1=date;
            }
            try{
                this.$store.dispatch('purchase/getUserPurchase',{page_no:pn,option:option,date:date1});
            }catch(error){
               console.log('error'+error)
            }
        },
        getUserPaymentlist(pn=1,option=null,date1=null){
            this.purchase=false;
            this.payment=true;
            var date='';
            if(date1==null){
                var today=new Date();
                if(today.getDate()<10){
                     date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+0+today.getDate();
                }else{
                     date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                }
                if(today.getMonth()<10){
                     date=today.getFullYear()+'-'+0+(today.getMonth()+1)+'-'+today.getDate();
                 }else{
                   date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                 }
                this.date=date;
                date1=date;
            }
            try{
                this.$store.dispatch('payment/getUserPayment',{page_no:pn,option:option,date:date1});
            }catch(error){
               console.log('error'+error)
            }
        },
        updatePurchase(id=null){
            this.formPurchaseVisible=true;
            this.UpdatePurchaseData=id;
            
        },
        UpdatePayment(id=null){
            this.formPaymentVisible=true;
            this.updatePaymentData=id;
        },
        changeDate(event){
            
            if(this.work_type=='purchase'){
               this.getUserPurchaselist(1,null,event.target.value)
                this.purchase=true;
                this.payment=false;
            }
            if(this.work_type=='payment'){
                this.purchase=false;
                this.payment=true;
                this.getUserPaymentlist(1,null,event.target.value)
            }
            
        },
        formTogglePurchase(){
            this.UpdateData=false;
            this.formPurchaseVisible=!this.formPurchaseVisible
        },showMessgae(msg){
            this.msg=msg;
            setTimeout(()=>{
                this.msg=null;
            },3000);
        },
        formTogglePayment(){
            this.UpdateData=false;
            this.formPaymentVisible=!this.formPaymentVisible;
        },changeType(event){
            this.work_type=event.target.value;
            if(this.work_type=='purchase'){
                this.getUserPurchaselist(1,null,this.date);
                this.purchase=true;
            }
            if(this.work_type=='payment'){
                this.purchase=false;
                this.getUserPaymentlist(1,null,this.date);
            }
        },
        getpdf(){
        var uid=this.$store.getters.isAuthenticate;
        var client_option='';
        var year='';
        var month='';
        var gst_type='';
        var gst_type_2='';
        var platform_option='';
        var dt_from=this.date;
        var dt_to='';
        var page_name='u'+this.work_type;
        var project_id='';
        var project_name='';
            /*try{
                this.$store.dispatch('pdf/getpdf',{page_name:this.heading});
            }catch(error){
               console.log('error'+error)
            }*/
             //window.open(`http://localhost/estimate/src/Database/pdf_download.php?project_id=${project_id}&project_name=${project_name}&page_name=${page_name}&client_option=${client_option}&year=${year}&month=${month}&gst_type=${gst_type}&gst_type_2=${gst_type_2}&platform_option=${platform_option}&dt_from=${dt_from}&dt_to=${dt_to}&uid=${uid}`);
            window.open(`/Database/pdf_download.php?project_id=${project_id}&project_name=${project_name}&page_name=${page_name}&client_option=${client_option}&year=${year}&month=${month}&gst_type=${gst_type}&gst_type_2=${gst_type_2}&platform_option=${platform_option}&dt_from=${dt_from}&dt_to=${dt_to}&uid=${uid}`);
 
        }

    },
    computed:{
        getpuchase(){
            return this.$store.getters['purchase/getPurchse'];
        },
        getPagination(){
            return this.$store.getters['purchase/getpagination'];
        },
        getPaginationText(){
            return this.$store.getters['purchase/getpagination_text']
        },
        getActive(){
            return this.$store.getters['purchase/getActive'];
        },
        getpayment(){
            return this.$store.getters['payment/getPayments'];
        },
        getActive1(){
            return this.$store.getters['payment/getActive'];
        },
        getPagination1(){
            return this.$store.getters['payment/getpagination'];
        },
        getPaginationText1(){
            return this.$store.getters['payment/getpagination_text']
        },
        
    },
    created(){
        this.getUserPurchaselist();
    }
}
</script>