<template>
  <base-dialog :show="!!error" title="An Error" @close="handleError"
    ><p>{{ error }}</p></base-dialog
  >
  <base-dialog fixed :show="isLoading" title="Processing...">
    <base-spinner></base-spinner>
  </base-dialog>
  <form class="p-5 border">
    <fieldset>
      <legend v-if="!update">Add Purchase</legend>
      <legend v-else>Update Purchase</legend>
      <div class="row">
        <input type="hidden" name="id" id="id" v-model.trim="id">
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="purchase_date"> Date <span>*</span> </label>
          <input
            type="date"
            id="purchase_date"
            ref="purchase_date"
            name="purchase_date"
            class="form-control form-control-sm"
            :class="{ invalid: invalidDate }"
            v-model="purchase_date"
            @blur="validInput"
          />
          <span v-if="invalidDate" :class="{ invalid: invalidDate }"
            >Choose Any Date</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="suppiler_name"> Supplier <span>*</span></label>
          <input
            type="text"
            ref="suppiler_name"
            id="suppiler_name"
            name="suppiler_name"
            class="form-control form-control-sm"
            v-model.trim="suppiler_name"
            :class="{ invalid: invalidSuppiler }"
            @blur="validInput"
          />
          <span
            v-if="invalidSuppiler"
            :class="{ invalid: invalidSuppiler }"
            >Invalid Suppiler Name</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="project_id"> Project <span>*</span></label>
          <select
            type="text"
            id="project_id"
            ref="project_id"
            name="project_id"
            class="form-control form-control-sm"
            :class="{ invalid: invalidProjectOption }"
            v-model.trim="project_id"
            @blur="validInput"
          >
          <option v-for="option in ProjectOption"  :key="option.int_project_id" :value="option.int_project_id">{{option.txt_project_name}}</option>
          </select>
          <span
            v-if="invalidProjectOption"
            :class="{ invalid: invalidProjectOption }"
            >Choose Project</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="item_id"> Item <span>*</span></label>
          <div class="input-group" style="padding:0px!important; margin:0px !important">
          <select
            type="text"
            id="item_id"
            ref="item_id"
            name="item_id"
            class="form-control form-control-sm"
            :class="{ invalid: invalidItemOption }"
            v-model.trim="item_id"
            @blur="validInput"
            @change="ItemChange"
          >
          <option v-for="option in ItemOption"  :key="option.int_item_id" :value="option.int_item_id">{{option.txt_item_name}}</option>
          </select>
          <span class="input-group-append " style="right:0px">
                <base-button class="btn" style="padding:0.2em .5em" @click.prevent="itemform=!itemform">
                    <i class="fa fa-add"></i>
                </base-button>
            </span>
        </div>
          <span
            v-if="invalidItemOption"
            :class="{ invalid: invalidItemOption }"
            >Choose Item</span
          >
        </div>
        <span class="text-success">{{itemMessage}}</span>

        <div class="row" v-if="itemform">
            
            <div class="col-sm-12 col-md-12 input-group" >
                <input type="text" id="additem" name="additem" class="form-control form-control-sm"  :class="{inavalid:invalidadditem}" ref="additem" v-model.trim="additem">
                
                <base-button mode="btn" type="button"  style="padding:0.2em .5em" @click="AddItem">Add Item</base-button>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="item_name"> Item Name<span>*</span></label>
          <input
            type="text"
            id="item_name"
            ref="item_name"
            name="item_name"
            class="form-control form-control-sm"
            v-model.trim="item_name"
            :class="{ invalid: invalidItemName }"
            @blur="validInput"
          />
          <span
            v-if="invalidItemName"
            :class="{ invalid: invalidItemName }"
            >Inavlid Item Name</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="bill_amount"> Amount</label>
          <input
            type="number"
            id="bill_amount"
            name="bill_amount"
            ref="bill_amount"
            class="form-control form-control-sm"
            :class="{ invalid: invalidAmount }"
            v-model.number="bill_amount"
            @blur="validInput"
          />
          <span
            v-if="invalidAmount"
            :class="{ invalid: invalidAmount }"
            >Inavlid Amount</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="purchase_detail"> Purchase  Details<span>*</span></label>
          <textarea
          id="purchase_detail"
            name="purchase_detail"
            ref="purchase_detail"
            class="form-control form-control-sm"
            :class="{ invalid: invalidPurchase }"
            v-model.number="purchase_detail"
            @blur="validInput"></textarea>
            <span
            v-if="invalidPurchase"
            :class="{ invalid: invalidPurchase }"
            >Enter Some Caption</span
          >
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-2">
          <label for="file" class="btn btn-sm fa fa-upload" > Add Image</label>
          <input type="file" ref="file" name="file" id="file" hidden  @change="showFileName($event)"/>
          <input type="hidden" v-model="file_name" />
          <span v-html="file"></span>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-right p-2" v-if="update">
          <base-button  @click.prevent="updateData" mode="btn"> Update </base-button >
          <base-button @click="close" mode="btn"> Close</base-button >
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-right p-2" v-else>
          <base-button @click="close" mode="btn" > Close</base-button >
          <base-button  @click.prevent="saveData(true)" mode="btn" > Save & Close </base-button >
          <base-button  @click.prevent="saveData" mode="btn"> Save & New </base-button >
        </div>
      </div>
    </fieldset>
  </form>
</template>
<script>
import BaseButton from '../UI/BaseButton.vue';
export default {
  components: { BaseButton },
  emits: ["close", "load","msg"],
  props: ["update"],
  data() {
    return {
      id:null,
      purchase_date:null,
      suppiler_name: null,
      project_id:null,
      item_id:null,
      item_name:null,
      bill_amount:null,
      purchase_detail:null,
      isLoading: false,
      error: null,
      invalidDate: false,
      invalidSuppiler:false,
      invalidProjectOption:false,
      invalidItemOption: false,
      invalidItemName:false,
      invalidAmount:false,
      invalidadditem:false,
      
      valid: false,
      ProjectOption:null,
      ItemOption:null,
      file:null,
      file_name:null,
      itemform:false,
      itemMessage:null,
    };
  },
  methods: {
    async saveData(selfclose = false) {
      let valid = null;
      this.validInput();
      if (
        this.invalidDate ||
        this.invalidSuppiler ||
        this.invalidProjectOption ||
        this.invalidItemOption ||
        this.invalidItemName ||
        this.invalidAmount
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid) {
        this.isLoading = true;
        try {
          this.$store.dispatch("purchase/addPurchase", {
            date: this.purchase_date,
            suppiler:this.suppiler_name,
            project:this.project_id,
            itemName:this.item_name,
            item_id:this.item_id,
            bill_amount:this.bill_amount,
            purchase_detail:this.purchase_detail,
            file : this.$refs.file.files[0],
          });
        } catch (error) {
          this.error = error;
          this.$emit("msg",error)
        }
        setTimeout(() => {
          this.isLoading = false;
          if (selfclose == true) {
            this.$emit("close");
          }
          this.$emit("msg","Purchase Added Successfully")
          this.$emit("load");
          this.purchase_date = null;
          this.suppiler_name = null;
          this.project_id = null;
          this.item_name = null;
          this.item_id=null;
          this.bill_amount=null;
        }, 2000);
      }
    },

    getdata() {
      if (this.update) {
        this.$store.dispatch("purchase/singleGetPurchase", { id: this.update });
        this.isLoading = true;
        setTimeout(() => {
          this.setData();
          this.isLoading=false;
        }, 2000);
      }
    },
    handleError() {
      this.error = null;
    },
    close() {
      this.$emit("close");
    },
    getOption(){
      var today=new Date();
      var date;
      if(today.getDate()<10){
        date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+0+today.getDate();
      }else{
        date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      }
      if(today.getMonth()<10){
        date=today.getFullYear()+'-'+0+(today.getMonth()+1)+'-'+today.getDate();
      }else{
        date=today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      }
      this.purchase_date=date;
      this.$store.dispatch("worker/getOption", {id:0}); 
      this.isLoading=true;
      setTimeout(() => {
          this.setOption();
          this.isLoading=false;
        }, 2000); 
    },
    ItemChange(event) {
      this.selected = event.target.value;
      var ItemText = event.target.options[event.target.options.selectedIndex].text;
      if(ItemText!='other'){
          this.item_name=ItemText;
      }else{
        this.item_name=null;
      }
      this.item_id=event.target.value;
      this.validInput();
      
    },
    validInput() {
      
      if(this.itemform){
        if(!this.additem){
          this.invalidadditem=true;
          this.$refs.additem.focus();
        }
        this.$refs.additem.focus();
      }
      else if (!this.purchase_date) {
        this.invalidDate = true;
        this.$refs.purchase_date.focus();
      }else if (!this.suppiler_name) {
        this.invalidSuppiler = true;
        this.$refs.suppiler_name.focus();
      }else if (!this.project_id) {
        this.invalidProjectOption = true;
        this.$refs.project_id.focus();
      }else if (!this.item_id) {
        this.invalidItemOption = true;
        this.$refs.item_id.focus();
      } else if (this.invalidItemOption) {
        this.invalidItemOption = true;
        this.$refs.item_id.focus();
      }else if (!this.item_name) {
        this.invalidItemName = true;
        this.$refs.item_name.focus();
      }else if (!this.bill_amount) {
        this.invalidAmount = true;
        this.$refs.bill_amount.focus();
      }

      
      if (this.purchase_date) {
        this.invalidDate = false;
      }
      if (this.suppiler_name) {
        this.invalidSuppiler = false;
      }
      if (this.project_id) {
        this.invalidProjectOption = false;
      }
      if (this.item_id) {
        this.invalidItemOption = false;
      }
      if (this.bill_amount) {
        this.invalidAmount = false;
      }
      if (this.item_name) {
        this.invalidItemName = false;
      }
    },

    setOption(){
      this.ProjectOption= this.$store.getters["worker/getProjectOption"];
      this.ItemOption= this.$store.getters["worker/getItemOption"];
    },
    setData() {
        
      const Data = this.$store.getters["purchase/getSinglePurchase"];
      this.id=Data[0].int_purchase_id;
      this.purchase_date = Data[0].dat_purchase;
      this.suppiler_name = Data[0].txt_supplier ;
      this.project_id = Data[0].int_project_id ;
      this.item_id = Data[0].int_item_id ;
      this.item_name = Data[0].txt_item_name ;
      this.bill_amount = Data[0].dbl_amount;
      this.purchase_detail = Data[0].txt_narration;
      this.file_name = Data[0].txt_bill_image;
      this.file = Data[0].txt_bill_image;
    },
    async updateData() {
      let valid = null;
      this.validInput();
      if (
        this.invalidDate ||
        this.invalidSuppiler ||
        this.invalidProjectOption ||
        this.invalidItemOption ||
        this.invalidItemName ||
        this.invalidAmount
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid) {
        this.isLoading = true;
        try {
          this.$store.dispatch("purchase/updatePurchase", {
            id:this.id,
            date: this.purchase_date,
            suppiler:this.suppiler_name,
            project:this.project_id,
            itemName:this.item_name,
            item_id:this.item_id,
            bill_amount:this.bill_amount,
            purchase_detail:this.purchase_detail,
            file : this.$refs.file.files[0],
            file_name :this.file_name,
          });
        } catch (error) {
          this.error = error;
          this.$emit("msg",error);
        }
        setTimeout(() => {
          this.isLoading = false;
          this.$emit("msg","Purchase Updated Successfully")
          this.$emit("load");
          this.$emit("close");
          this.purchase_date = null;
          this.suppiler_name = null;
          this.project_id = null;
          this.item_name = null;
          this.item_id=null;
          this.bill_amount=null;
        }, 2000);
      }
    },
    async AddItem(){
        this.isLoading=true;
        if(this.additem){
        try{
            
             this.$store.dispatch("purchase/additem", {item:this.additem});
             this.itemMessage="Item Added SuccesFully.";
            setTimeout(() => {
                this.getOption();
            }, 1000);
             setTimeout(() => {
               this.ItemOption= this.$store.getters["worker/getItemOption"];
                this.additem=null;
                this.itemMessage=null;         
                this.isLoading=false;
            }, 2500);
            
            this.itemform=false;
            
        }catch(error){
            console.log('Purchase Add Item Error')
        }
        }else{
          this.isLoading=false;
              this.itemMessage="Enter Valid Item.";
              setTimeout(() => {
                
                this.itemMessage=null;         
                
            }, 2000);
            }
    },showFileName(event){
      this.file=event.target.files[0].name;      
    },

  },
  computed: {},
  created() {
    
    this.getdata();
    this.getOption();
  },
};
</script>
<style scoped>
label span {
  color: red;
}
.invalid {
  color: red;
}
select.invalid:focus,
select.invalid:active
input.invalid:focus,
input.invalid:active,
textarea.invalid:focus,
textarea.invalid:active,
textarea.invalid {
  border: 2px solid rgb(150, 145, 145);
  outline: 2px solid red;
}

</style>
